import { UPM_DATA_LAYER } from "@/boot/gtm";
import router from "@/router";

export enum AppEvents {
  CLIENT_AUTHENTICATED = "client_authenticated",
  CLIENT_LOGGED_OUT = "client_logged_out",
  LICENSE_PAYWALL_MODAL_OPENED = "license_paywall_modal_opened",
  LICENSE_UPGRADE_COMPLETED = "license_upgrade_completed",
  LICENSE_UPGRADE_INITIATED = "license_upgrade_initiated",
  LICENSE_UPGRADE_MODAL_OPENED = "license_upgrade_modal_opened",
  PRODUCT_TERM_SELECTED = "product_term_selected",
  SHOP_CURRENCY_CHANGED = "shop_currency_changed",
  SHOP_ITEM_ATTRIBUTE_SELECTION_CHANGED = "shop_item_attribute_selection_changed",
  SHOP_ITEM_OPTION_SELECTION_CHANGED = "shop_item_option_selection_changed",
  SHOP_ITEM_QUANTITY_CHANGED = "shop_item_quantity_changed",
  SHOP_ITEM_SHARE_LINK_COPIED = "shop_item_share_link_copied",
  SHOP_ITEM_VIEWED = "shop_item_viewed",
  STAFF_USER_AUTHENTICATED = "staff_user_authenticated",
  STAFF_USER_LOGGED_OUT = "staff_user_logged_out"
}

export function useAppEvents() {
  const pushEvent = (event: AppEvents, meta?: Record<string, any>) => {
    try {
      const payload = {
        event: `upm.${event}`,
        meta: {
          ...meta,
          route: router?.currentRoute?.path
        }
      };
      UPM_DATA_LAYER.push({ ...payload });
      if (import.meta.env.DEV)
        // eslint-disable-next-line no-console
        console.info(
          `%cUPM-Event:`,
          "color:rgb(112, 71, 235);font-weight:bold;",
          payload
        );
    } catch {
      // Silent catch – non-blocking
    }
  };

  return {
    pushEvent
  };
}
