import type { IDataState } from "@/store/modules/data";
import { DEFAULT_LIST_SCOPE } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { IState } from "@/store";
import _ from "@/boot/lodash";
import { Methods } from "@/models/methods";
import type { ActionTree, GetterTree } from "vuex";
import type {
  IPromotion,
  IPromotionProduct,
  IPromotionFormProduct,
  IPromotionForm,
  IPromotionCurrency,
  IPromotionPriceList,
  IPromotionBillingCycles
} from "@/models/promotions";
import { PromotionTypes } from "@/data/enums/promotions";
import type { ApiPathGetter } from "@/models/api";

const individualProductFields = [
  "id",
  "product_id",
  "amount",
  "amount_formatted",
  "min_quantity",
  "max_quantity",
  "order",
  "type",
  "currency_id"
];

const mandatoryProductFields = [
  "id",
  "product_id",
  "min_quantity",
  "max_quantity"
];

const selectedProductFields = ["product_id"];

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/promotions`;
    return { admin };
  },
  promotionScope: () => id => {
    return `$promotion_${id}`;
  },
  params:
    (state: IDataState) =>
    (scope = DEFAULT_LIST_SCOPE) => {
      return _.get(state, `${scope}.params`, {});
    },
  mapToFESchema:
    () =>
    ({
      data,
      fields = []
    }: {
      data: IPromotionProduct | IPromotion;
      fields: string[];
    }) => {
      const dateFields = {};
      if (_.isNull(_.get(data, "from_date"))) dateFields["from_date"] = "";
      if (_.isNull(_.get(data, "to_date"))) dateFields["to_date"] = "";

      return _.merge(
        fields.length ? _.pick(data, fields) : {},
        {
          currencies: _.map(
            data.currencies || [],
            (currency: IPromotionCurrency) => currency.currency_id
          ),
          pricelists: _.map(
            data.pricelists || [],
            (pricelist: IPromotionPriceList) => pricelist.pricelist_id
          ),
          billing_cycles: _.map(
            data.billing_cycles || [],
            (billingCycle: IPromotionBillingCycles) =>
              billingCycle.billing_cycle_months
          )
        },
        dateFields
      ) as IPromotionForm | IPromotionFormProduct;
    },
  mapToBESchema:
    () =>
    (data, fields = []) => {
      if (data.min_quantity === "") data.min_quantity = null;
      if (data.max_quantity === "") data.max_quantity = null;

      return _.merge(
        fields?.length ? _.pick(data, fields) : data,
        _.isEmpty(data?.currencies)
          ? {}
          : { currencies: _.map(data.currencies, id => ({ currency_id: id })) },
        _.isEmpty(data?.billing_cycles)
          ? {}
          : {
              billing_cycles: _.map(data.billing_cycles, id => ({
                billing_cycle_months: id
              }))
            },
        _.isEmpty(data?.pricelists)
          ? {}
          : { pricelists: _.map(data.pricelists, id => ({ pricelist_id: id })) }
      );
    },
  promotionFields: () => (promotion: IPromotion) => {
    const fields = [
      "active",
      "amount",
      "autoapply",
      "billing_cycles",
      "brand_id",
      "code",
      "constant_discount",
      "combined",
      "currencies",
      "currency_id",
      "for_existing_clients",
      "for_new_clients",
      "for_upgrade_clients",
      "from_date",
      "global",
      "max_uses",
      "max_uses_per_client",
      "max_quantity",
      "min_quantity",
      "name",
      "order_type",
      "pricelists",
      "promo_recurring_count",
      "recurring",
      "short_description",
      "show_on_catalog",
      "to_date",
      "type"
    ];

    if (!_.isEmpty(promotion)) fields.push("mandatory_products");

    return fields;
  },
  getIndividualProducts:
    (state, getters) => (products: IPromotionProduct[]) => {
      return products.map(product =>
        getters["mapToFESchema"]({
          data: product,
          fields: individualProductFields
        })
      );
    },
  getSelectedProducts: (state, getters) => (products: IPromotionProduct[]) => {
    return products.map(product =>
      getters["mapToFESchema"]({
        data: product,
        fields: selectedProductFields
      })
    );
  },
  getMandatoryProducts: (state, getters) => (products: IPromotionProduct[]) => {
    return products.map(product =>
      getters["mapToFESchema"]({
        data: product,
        fields: mandatoryProductFields
      })
    );
  },
  getPromotionType: () => (promotion: IPromotion) => {
    return promotion.global
      ? PromotionTypes.GLOBAL
      : promotion.constant_discount
      ? PromotionTypes.SELECTED_PRODUCTS
      : PromotionTypes.INDIVIDUAL;
  },
  mapPromotionToForm: (state, getters) => (promotion: IPromotion) => {
    const {
      getIndividualProducts,
      getSelectedProducts,
      getMandatoryProducts,
      getPromotionType,
      promotionFields,
      mapToFESchema
    } = getters;

    const products = promotion?.products || [];
    const type = getPromotionType(promotion);

    const mappedProducts =
      type === PromotionTypes.INDIVIDUAL
        ? getIndividualProducts(products)
        : type === PromotionTypes.SELECTED_PRODUCTS
        ? getSelectedProducts(products)
        : promotion.products;

    return _.merge(
      mapToFESchema({ data: promotion, fields: promotionFields(promotion) }),
      {
        products: mappedProducts,
        mandatory_products: getMandatoryProducts(
          promotion.mandatory_products || []
        )
      }
    );
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: `${getters.apiPath().admin}`,
        storeModule: DataModules.CATALOGUE_PROMOTIONS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${_.get(payload, "id")}`,
        storeModule: DataModules.CATALOGUE_PROMOTIONS
      },
      { root: true }
    );
  },

  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: `${getters.apiPath().admin}`,
        storeModule: DataModules.CATALOGUE_PROMOTIONS
      },
      { root: true }
    );
  },

  update: async ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        data: payload.data,
        path: `${getters.apiPath().admin}/${_.get(payload, "promotionId")}`,
        storeModule: DataModules.CATALOGUE_PROMOTIONS
      },
      { root: true }
    );
  },

  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath().admin}/${_.get(payload, "id")}`,
        storeModule: DataModules.CATALOGUE_PROMOTIONS
      },
      { root: true }
    );
  },

  restore: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${getters.apiPath().admin}/${_.get(payload, "id")}/restore`,
        storeModule: DataModules.CATALOGUE_PROMOTIONS
      },
      { root: true }
    );
  },

  detachPromotionProduct: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath().admin}/${_.get(
          payload,
          "promotionId"
        )}/detach/${_.get(payload, "productId")}`,
        storeModule: DataModules.CATALOGUE_PROMOTIONS
      },
      { root: true }
    );
  },

  openManageProductModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/catalogue/promotions/manageProductModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  },

  // Individual Products
  // --------------------------------------

  updateIndividualProduct: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        path: `${getters.apiPath().admin}/${_.get(
          payload,
          "promotionId"
        )}/assign/${_.get(payload, "productId")}`,
        data: payload.promotionProduct,
        storeModule: DataModules.CATALOGUE_PROMOTIONS
      },
      { root: true }
    );
  },

  reorderIndividualProducts: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath().admin}/${_.get(
          payload,
          "promotionId"
        )}/products/order`,
        requestConfig: {
          data: payload
        },
        storeModule: DataModules.CATALOGUE_PROMOTIONS
      },
      { root: true }
    );
  },

  // Single Products
  // --------------------------------------

  attachSelectedProduct: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        path: `${getters.apiPath().admin}/${_.get(
          payload,
          "promotionId"
        )}/assign`,
        data: payload.promotionProduct,
        storeModule: DataModules.CATALOGUE_PROMOTIONS
      },
      { root: true }
    );
  },

  detachSelectedProduct: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath().admin}/${_.get(
          payload,
          "promotionId"
        )}/detach/${_.get(payload, "productId")}`,
        storeModule: DataModules.CATALOGUE_PROMOTIONS
      },
      { root: true }
    );
  },

  detachAllSelectedProducts: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath().admin}/${_.get(
          payload,
          "promotionId"
        )}/detach`,
        storeModule: DataModules.CATALOGUE_PROMOTIONS
      },
      { root: true }
    );
  },

  syncSelectedProducts: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${_.get(
          payload,
          "promotionId"
        )}/assign/sync`,
        requestConfig: {
          data: {
            configurations: payload.configurations
          }
        },
        storeModule: DataModules.CATALOGUE_PROMOTIONS
      },
      { root: true }
    );
  },

  // Mandatory Products
  // --------------------------------------

  attachMandatoryProduct: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        path: `${getters.apiPath().admin}/${_.get(
          payload,
          "promotionId"
        )}/mandatory_products/assign`,
        data: payload.promotionProduct,
        storeModule: DataModules.CATALOGUE_PROMOTIONS
      },
      { root: true }
    );
  },

  updateMandatoryProduct: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        path: `${getters.apiPath().admin}/${_.get(
          payload,
          "promotionId"
        )}/mandatory_products/assign/${_.get(payload, "productId")}`,
        data: payload.promotionProduct,
        storeModule: DataModules.CATALOGUE_PROMOTIONS
      },
      { root: true }
    );
  },

  detachMandatoryProduct: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath().admin}/${_.get(
          payload,
          "promotionId"
        )}/mandatory_products/detach/${_.get(payload, "productId")}`,
        storeModule: DataModules.CATALOGUE_PROMOTIONS
      },
      { root: true }
    );
  },

  detachAllMandatoryProducts: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath().admin}/${_.get(
          payload,
          "promotionId"
        )}/mandatory_products/detach`,
        storeModule: DataModules.CATALOGUE_PROMOTIONS
      },
      { root: true }
    );
  },

  openMandatoryProductsConfigurationModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/admin/catalogue/promotions/manageMandatoryConfigurationModal.vue"
            ),
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
